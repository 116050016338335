const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/about"],
    exact: true,
    component: "About",
  },
  {
    path: ["/our-work"],
    exact: true,
    component: "WhatWeDo",
  },
  {
    path: ["/camp"],
    exact: true,
    component: "Camp",
  },
  {
    path: ["/founder"],
    exact: true,
    component: "Founder",
  },
  {
    path: ["/shop"],
    exact: true,
    component: "Shop",
  },
  {
    path: ["/faq"],
    exact: true,
    component: "Faq",
  },
  {
    path: ["/contact"],
    exact: true,
    component: "Contact",
  },
  {
    path: ["/events"],
    exact: true,
    component: "Events",
  },
  // {
  //   path: ["/donations"],
  //   exact: true,
  //   component: "Donations",
  // },
  // {
  //   path: ["/advanced_learning"],
  //   exact: true,
  //   component: "AdvancedLearning",
  // },
  {
    path: ["/impact_esd"],
    exact: true,
    component: "ImpactEsd",
  },
];

export default routes;
